import React, {useEffect, useState} from 'react'
import {Box, Button, IconButton, styled, Typography} from "@mui/material";
import {Loader} from "../../shared/Loader";
import {ScreenLayout} from "../../shared/ScreenLayout";

import {
    getAcademyItemThunk, FeedItem, TrainingVideoPage, clearPosts
} from "../../features/academyContent/academyContentSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import {useNavigate, useParams} from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {routes} from "../../constants/routes";
import {HLSVideoPlayer} from "../../shared/HLSVideoPlayer";
import {Analytics} from "../../lib/analytics";
import {useVideoDimensions} from "../../hooks/useVideoDimensions";
import DynamicTopBarColor from '../../DynamicTopBarColor';
import {useTheme} from "@mui/material/styles";
import Divider from '@mui/material/Divider';
import {setCurrentPostPath} from "../../util/storage";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { generateVirtualCoachPrompt } from "../../services/api";


const StyledBackButton = styled(Button)(() => ({
    fontSize: "32px",
    fontWeight: "700",
    margin: "0",
    padding: "10px",
    minWidth: "5px"
}));

const ChatBubbleLeft = styled(Box)(({ theme }) => ({
    padding: "10px",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    borderRadius: "10px",
    marginBottom: "10px",
    textAlign: "left",
    display: "inline-block",
    maxWidth: "100%", 
    wordWrap: "break-word",
    marginRight: "auto"
}));

const ChatBubbleRight = styled(Box)(({ theme }) => ({
    maxWidth: "70%",
    backgroundColor: theme.palette.grey[300],
    padding: "10px",
    borderRadius: "10px",
    marginBottom: "10px",
    textAlign: "right",
    marginLeft: "auto"
}));

const ChatContainer = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    flexGrow: 1,
    overflowY: "auto"
}));

const InputContainer = styled(Box)(() => ({
    width: "100%",
    padding: "10px",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "auto"
}));

const InputField = styled(Box)(() => ({
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    borderRadius: "25px",
    padding: "5px 10px"
}));

export const TrainingPost = (): React.ReactElement => {
    const navigate = useNavigate()
    const theme = useTheme();
    const params = useParams();
    const postId = params.pageId ? params.pageId : ""

    const dispatch = useAppDispatch()

    const postContent = useAppSelector<FeedItem|undefined>((state: RootState) => state.academyContent.trainingItem);
    const fetchingItem = useAppSelector<boolean>((state: RootState) => state.academyContent.fetchingTrainingItem);

    const post = postContent as TrainingVideoPage

    const {dimensions, error } = useVideoDimensions(post?.media?.video?.url || "")

    const [userPrompt, setUserPrompt] = useState('');
    const [loading, setLoading] = useState(false);
    const [question, setQuestion] = useState('');
    const [response, setResponse] = useState('');

    useEffect(() => {
        dispatch(clearPosts())
    }, [dispatch]);

    useEffect(()=>{
        console.log(dimensions)
        console.log("width:", dimensions?.width, "height:", dimensions?.height)
        console.log(error)
    },[dimensions, error])

    useEffect(() => {
        if(postId)
            dispatch(getAcademyItemThunk(postId))
    },[postId, dispatch])

    useEffect(() => {
        Analytics.trainingVideoView((postId));
        setCurrentPostPath(routes.trainingVideo.replace(":pageId",postId))
    },[postId])

    const sendQuestionToAPI = async (questionText:string) => {
        try {
            setLoading(true); 
            const apiResponse = await generateVirtualCoachPrompt(questionText, theme.custom.name, postId, post.tags[0].toLowerCase());
            setResponse(apiResponse);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching response from API: ', error);
            setLoading(false);
        }
    };

    const handleSubmit = (e:any) => {
        e.preventDefault();
        setQuestion(userPrompt);
        sendQuestionToAPI(userPrompt); 
        setUserPrompt(''); 
    };

    return (
        <>
            <DynamicTopBarColor color={theme.custom.mainBackgroundColor}/>
            <Box sx={{display: "flex", flex: 1}}>
                <ScreenLayout
                    title={<StyledBackButton onClick={()=> navigate(postContent ? routes.train + "#item-" + postContent.id : routes.train)}><KeyboardBackspaceIcon/></StyledBackButton>}
                    ctaTitle={""}
                    onAction={()=>{}}
                    displayCta={false}
                    contentMarginTop={"0"}
                    contentPadding={"0"}
                    headerMarginTop={"0"}
                    headerPadding={"0"}
                    contentFeedBackgroundColor="#FFFFFF"
                ><>
                    {
                        (!(postContent === undefined) || fetchingItem)
                            && post
                            && post.media
                            && post.media.video
                            // && dimensions
                        ?
                        <>
                                <HLSVideoPlayer
                                    hlsSrc={!!post.media ? post.media.video.stream.url : ""}
                                    mp4Src={!!post.media ? post.media.video.url : ""}
                                    surl={!!post.media ? post.media.surl : undefined}
                                    videoPageId={post.id}
                                    controls
                                    width="100%"
                                    height={dimensions && dimensions.height >= dimensions.width ? "250px" : undefined}
                                    // src={!!post.media ? post.media.video.url : ""}
                                    autoPlay
                                    playsInline
                                />
                            <Box sx={{padding: "16px 16px 0 16px"}}>
                                <Typography sx={{fontSize: "18px", fontWeight: "600"}}>{post.title}</Typography>
                                <Typography
                                    sx={{
                                        marginTop: "8px",
                                        color: "#666666",
                                        fontWeight: 400,
                                        fontSize: "12px"
                                    }}
                                    dangerouslySetInnerHTML={{ __html: post.tags.join('&nbsp;&nbsp;&#8226;&nbsp;&nbsp;') }}
                                />
                                {/*<Typography variant="caption" color="secondary">*/}
                                {/*    {timeSince(post.meta.first_published_at)}*/}
                                {/*</Typography>*/}
                            </Box>
                            {/*<Box sx={{padding: "8px 5px"}}>*/}
                            {/*    <IconButton aria-label="like" sx={{flexShrink: 0, borderRadius: "16px"}} onClick={() => {*/}
                            {/*        dispatch(setSendingLikeTo(post.id))*/}
                            {/*        dispatch(likeItemThunk(post.id))*/}
                            {/*        setLikeSent(!likeSent)*/}
                            {/*    }}  >*/}
                            {/*        { liked ?*/}
                            {/*            <FavoriteIcon/>*/}
                            {/*            :*/}
                            {/*            <FavoriteBorderIcon/>*/}
                            {/*        }*/}
                            {/*        {(post.reactions.count + likeCounted) > 0 && (post.reactions.count + likeCounted) }*/}
                            {/*    </IconButton>*/}
                            {/*</Box>*/}
                            <Box sx={{ paddingTop: '8px', paddingBottom: '0px', paddingLeft: '12px', paddingRight: '12px' }}>
                                <Divider sx={{ borderBottomWidth: '0.1px', borderColor: theme.custom.wl.bottomAppTabs.borderTop }}/>
                            </Box>
                            <>
                                { theme.custom.name !== 'ffc' && <Box
                                    sx={{
                                        marginTop: "-8px",
                                        paddingBottom: "0px",
                                        paddingLeft: "16px",
                                        paddingRight: "16px",
                                        color: theme.custom.darkerPrimaryColor
                                    }}
                                    dangerouslySetInnerHTML={{ __html: post.body }}
                                /> }
                                { theme.custom.name === 'ffc' && <>
                                <ChatContainer>
                                    {question && (
                                        <ChatBubbleRight>
                                            <Typography variant="h6">{question}</Typography>
                                        </ChatBubbleRight>
                                    )}
                                    {loading ? (
                                        <ChatBubbleLeft>
                                            <Typography variant="h6">...</Typography>
                                        </ChatBubbleLeft>
                                    ) : (
                                        response && (
                                            <ChatBubbleLeft>
                                                <Typography variant="h6">{response}</Typography>
                                            </ChatBubbleLeft>
                                        )
                                    )}
                                </ChatContainer>
                                <InputContainer>
                                    <form onSubmit={handleSubmit} style={{ display: "flex", width: "100%" }}>
                                        <InputField>
                                            <input
                                                type="text"
                                                placeholder="Ask a question about the video..."
                                                value={userPrompt}
                                                onChange={(e) => setUserPrompt(e.target.value)}
                                                style={{
                                                    flexGrow: 1,
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                    padding: "10px",
                                                    fontSize: "16px"
                                                }}
                                                required
                                            />
                                            <IconButton type="submit" color="primary">
                                                <ArrowUpwardIcon />
                                            </IconButton>
                                        </InputField>
                                    </form>
                                </InputContainer></>}
                            </>
                        </>
                        :
                        <Loader/>
                    }
                </>
                </ScreenLayout>
            </Box>
        </>
    )

}